<template>
  <v-row justify="center">
    <v-dialog v-model="visible" persistent max-width="600px">
      <v-card>
        <v-card-title>Anulación de DTE</v-card-title>
        <v-card-text>
          <p v-if="responseError && !valid">
            Los datos enviados no son correctos, haga click sobre la X de los
            campos inválidos para intentar de nuevo.
          </p>
          <v-form ref="form" v-model="valid" :lazy-validation="false">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Correlativo Interno de DTE"
                  v-model="body.correrativo_interno_dte_anulacion"
                  :rules="correlativoRules"
                  :error-messages="correlativoErrors"
                  clearable
                  @click:clear="correlativoErrors = []"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Autorización DTE"
                  v-model="body.autorizacion_dte_anulacion"
                  :rules="uuidRules"
                  :error-messages="autorizacionErrors"
                  clearable
                  @click:clear="autorizacionErrors = []"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Motivo"
                  v-model="body.motivo"
                  :rules="textRules"
                  :error-messages="motivoErrors"
                  clearable
                  @click:clear="motivoErrors = []"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="yellow"
            @click="
              $emit('close');
              resetForm();
            "
          >
            Cerrar
          </v-btn>
          <v-btn color="success" @click="createAnnulment()" :disabled="!valid">
            Crear Anulación
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  props: {
    visible: { type: Boolean, required: true }
  },
  data: () => ({
    correlativoErrors: [],
    autorizacionErrors: [],
    motivoErrors: [],
    responseError: false,
    valid: false,
    isDateRangeValid: false,
    body: {
      motivo: "",
      autorizacion_dte_anulacion: "",
      correrativo_interno_dte_anulacion: ""
    },
    textRules: [rules.required, rules.minLength(5)],
    uuidRules: [rules.required, rules.exactlyLength(36)],
    correlativoRules: [rules.required, rules.minLength(12)]
  }),
  methods: {
    createAnnulment() {
      this.valid = false;
      requests.post("/api/annulment-dte/", this.body).then(response => {
        if (response.status == 201) {
          this.resetForm();
          this.$toasted.global.info({
            message: "La anulación del DTE fue creada"
          });
          this.$emit("updated");
          this.$emit("close");
        } else if (response.status == 400) {
          this.responseError = true;
          if (response.data.autorizacion_dte_anulacion != undefined) {
            this.autorizacionErrors = response.data.autorizacion_dte_anulacion;
          }
          if (response.data.correrativo_interno_dte_anulacion != undefined) {
            this.correlativoErrors =
              response.data.correrativo_interno_dte_anulacion;
          }
          if (response.data.motivo != undefined) {
            this.motivoErrors = response.data.motivo;
          }
        }
      });
    },
    resetForm() {
      this.body = {
        motivo: "",
        autorizacion_dte_anulacion: "",
        correrativo_interno_dte_anulacion: ""
      };
      this.valid = false;
      this.responseError = false;
      this.$refs.form.reset();
    }
  }
};
</script>
